import React from "react";
import { recipeType } from "../../../types";

const StructuredRecipeData = ({
  recipe: { title, type, serves, ingredients, steps }
}) => (
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org/",
        "@type": "Recipe",
        "name": "${title}",
        "description": "A simple, delicious, tried and tested, vegan recipe for ${title.toLowerCase()}.",
        "image": "https://misohungry.co.uk/favicon.ico",
        "recipeCategory": "${type}",
        ${serves ? `"recipeYield": "${serves} servings",` : ""}
        "recipeIngredient": [${ingredients.map(ingredient =>
          Array.isArray(ingredient)
            ? ingredientText(ingredient[0])
            : ingredientText(ingredient)
        )}],
        "recipeInstructions": [${steps.map(
          step => `{
            "@type": "HowToStep",
            "text": "${step.instruction || step}"
          }`
        )}]
      }`}
  </script>
);

const ingredientText = ({ amount, name, optional }) =>
  `"${amount ? `${amount} ` : ""}${name}${optional ? " (optional)" : ""}"`;

StructuredRecipeData.propTypes = {
  recipe: recipeType.isRequired
};

export default StructuredRecipeData;
