import { shape, string, bool, arrayOf, oneOfType, number } from "prop-types";

export const ingredientType = shape({
  amount: string,
  name: string.isRequired,
  slug: string,
  optional: bool
});

export const multiIngredientType = shape({
  variants: arrayOf(
    shape({
      amount: string,
      name: string.isRequired,
      slug: string
    })
  ).isRequired,
  optional: bool
});

export const singleOrMultiIngredientType = oneOfType([
  ingredientType,
  multiIngredientType
]);

export const ingredientsType = arrayOf(singleOrMultiIngredientType);

export const stepsType = arrayOf(
  oneOfType([
    string,
    shape({ instruction: string.isRequired, note: string.isRequired })
  ])
);

export const recipeType = shape({
  title: string.isRequired,
  type: string.isRequired,
  serves: number,
  ingredients: ingredientsType.isRequired,
  steps: stepsType.isRequired
});
