import React, { useState } from "react";
import "./Ingredients.scss";
import RecipeToggle from "../recipe-toggle/RecipeToggle";
import IngredientItem from "./ingredient-item/IngredientItem";
import { ingredientsType } from "../../../types";

const Ingredients = ({ ingredients }) => {
  const [displayOptionals, setDisplayOptionals] = useState(true);

  const hasOptionalIngredients = () =>
    ingredients.filter(({ optional }) => optional).length > 0;

  return (
    <React.Fragment>
      <div className="Ingredients-headingContainer">
        <h2 className="Ingredients-heading">Ingredients</h2>
        {hasOptionalIngredients() && (
          <RecipeToggle onToggled={toggled => setDisplayOptionals(toggled)} />
        )}
      </div>
      <ul className="Ingredients-list">
        {ingredients
          .filter(({ optional }) => !optional || displayOptionals)
          .map((ingredient, index) => (
            <IngredientItem key={index} ingredient={ingredient} />
          ))}
      </ul>
    </React.Fragment>
  );
};

Ingredients.propTypes = {
  ingredients: ingredientsType.isRequired
};

export default Ingredients;
