import React from "react";
import "./Header.scss";
import logo from "./logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Header = () => (
  <header className="Header">
    <span className="Header-text">Miso</span>
    <Link to="/" className="Header-itemContent">
      <img src={logo} className="Header-logo" alt="Miso Hungry logo" />
    </Link>
    <span className="Header-text">Hungry</span>
    <Link to="/about" className="Header-about">
      <FontAwesomeIcon icon={faInfoCircle} size="lg" />
    </Link>
  </header>
);

export default Header;
