import React from "react";
import "./About.scss";
import { Link } from "react-router-dom";

const About = () => (
  <div className="About">
    <h1 className="About-heading">About Miso Hungry</h1>
    <p>Miso Hungry is a website with one aim:</p>
    <blockquote className="About--emphasise">
      Providing simple, great-tasting, vegan recipes in a minimalist,
      no-nonsense manner.
    </blockquote>
    <p>
      No ads. No essays. No comments. No photos that leave you with unrealistic
      expectations. Just easy, tried and tested, vegan recipes with minimal
      ingredients.
    </p>
    <p>
      I created it as a personal reference for all of my favourite recipes, but
      if you stumble across it and like what you see, then feel free to use it{" "}
      <span role="img" aria-label="Smile!">
        😊
      </span>
    </p>
    <p>
      More and more recipes will appear over time as I discover them, and if you
      have any that you'd like me to try out&mdash;and possibly
      upload&mdash;then you can contact me at danharris293@live.co.uk.
    </p>
    <p>
      You may notice that the recipes don't explicitly state vegan ingredients
      all of the time; this is to leave it open for you to use whatever you have
      that fits. If a recipe requires milk, I'm sure soy will be as good as
      almond, or rice, or any other alternative. If a recipe requires butter,
      take your pick of the many vegan alternatives that are out there... You
      get the point.
    </p>
    <p>
      Also, don't feel that you have to follow the recipes exactly&mdash;I
      rarely do. Use them as a base, and try whatever variations tickle your
      fancy. The{" "}
      <Link to="black-bean-stew" className="About--link">
        Black Bean Stew
      </Link>{" "}
      would probably still taste great if you used chickpeas or kidney beans
      instead. The{" "}
      <Link to="chocolate-quinoa" className="About--link">
        Chocolate Quinoa
      </Link>{" "}
      may even work with couscous instead&mdash;who knows?! Again, you get the
      point.
    </p>
    <p>
      Anyway, enjoy the recipes, and happy eating! And if you're not already...
    </p>
    <p className="About--emphasise">Try vegan.</p>
    <p>It's kinder and easier than you might think.</p>
    <hr />
    <p>
      <a
        href="https://medium.com/@danharris293/why-asking-why-are-you-vegan-makes-no-sense-fb0e823de68a"
        className="About--link About--deemphasise"
      >
        Find out what it means to be vegan...
      </a>
      <br />
      <a
        href="https://www.challenge22.com/challenge22/"
        className="About--link About--deemphasise"
      >
        Try a 22 day vegan challenge...
      </a>
    </p>
  </div>
);

export default About;
