import React, { useState } from "react";
import "./Steps.scss";
import RecipeToggle from "../recipe-toggle/RecipeToggle";
import { stepsType } from "../../../types";

const Steps = ({ steps }) => {
  const [displayNotes, setDisplayNotes] = useState(true);

  const hasStepsWithNotes = () => steps.filter(step => step.note).length > 0;

  return (
    <React.Fragment>
      <div className="Steps-headingContainer">
        <h2 className="Steps-heading">Method</h2>
        {hasStepsWithNotes() && (
          <RecipeToggle onToggled={toggled => setDisplayNotes(toggled)} />
        )}
      </div>
      <ol className="Steps-list">
        {steps.map((step, index) =>
          step.instruction ? (
            <li key={index}>
              {step.instruction}
              {displayNotes && (
                <ul className="Steps-note">
                  <li>{step.note}</li>
                </ul>
              )}
            </li>
          ) : (
            <li key={index}>{step}</li>
          )
        )}
      </ol>
    </React.Fragment>
  );
};

Steps.propTypes = { steps: stepsType.isRequired };

export default Steps;
