import React from "react";
import "./Ingredient.scss";
import { ingredientType } from "../../../../../types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { element } from "prop-types";

const Ingredient = ({
  ingredient: { amount, name, slug, optional },
  icon = <FontAwesomeIcon icon={faCircle} />
}) => (
  <div className="Ingredient">
    <div className="Ingredient-markerContainer">{icon}</div>
    <li>
      {amount && `${amount} `}
      {slug ? <Link to={slug}>{name}</Link> : name}
      {optional && ` (optional)`}
    </li>
  </div>
);

Ingredient.propTypes = {
  ingredient: ingredientType.isRequired,
  icon: element
};

export default Ingredient;
