import React from "react";
import { Helmet } from "react-helmet";
import { string } from "prop-types";

const MisoMeta = ({ title, description }) => {
  const calculatedTitle = `Miso Hungry | ${title || "Simple, vegan recipes"}`;
  const calculatedDescription =
    description ||
    "A selection of simple, tried and tested, vegan recipes. Miso Hungry redesigns the typical recipe website with a focus on minimalism, simplicity, and ease of use.";

  return (
    <Helmet
      title={calculatedTitle}
      meta={[
        { property: "og:title", content: calculatedTitle },
        { name: "description", content: calculatedDescription },
        { property: "og:description", content: calculatedDescription }
      ]}
    />
  );
};

MisoMeta.propTypes = {
  title: string,
  description: string
};

export default MisoMeta;
