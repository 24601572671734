import React from "react";
import MultiIngredient from "./multi-ingredient/MultiIngredient";
import Ingredient from "./ingredient/Ingredient";
import { singleOrMultiIngredientType } from "../../../../types";

const IngredientItem = ({ ingredient }) =>
  ingredient.variants ? (
    <MultiIngredient multiIngredient={ingredient} />
  ) : (
    <Ingredient ingredient={ingredient} />
  );

IngredientItem.propTypes = {
  ingredient: singleOrMultiIngredientType.isRequired
};

export default IngredientItem;
