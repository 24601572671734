import React, { useEffect } from "react";
import "./App.scss";
import MisoMeta from "./miso-meta/MisoMeta";
import Header from "./header/Header";
import RecipeList from "./recipe-list/RecipeList";
import Recipe from "./recipe/Recipe";
import About from "./about/About";
import { findRecipe } from "../data/recipes";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

const App = withRouter(({ location: { pathname } }) => {
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return (
    <div className="App">
      <MisoMeta />
      <Header />
      <Switch>
        <Route exact path="/" component={RecipeList} />
        <Route path="/about" component={About} />
        <Route
          path="/:recipe"
          render={({ match }) => {
            const matchingRecipe = findRecipe(match.params.recipe);
            return matchingRecipe ? (
              <Recipe recipe={matchingRecipe} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
      </Switch>
    </div>
  );
});

export default App;
