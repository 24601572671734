import React from "react";
import "./RecipeList.scss";
import { ALL_RECIPES } from "../../data/recipes";
import { Link } from "react-router-dom";
import classNames from "classnames";

const RecipeList = () => (
  <ul className="RecipeList">
    {ALL_RECIPES.map(({ slug, title, type }, index) => (
      <li className="RecipeList-item" key={slug}>
        <Link
          to={slug}
          className={classNames(
            "RecipeList-itemLink",
            "RecipeList-itemLink--color" + (index % 4)
          )}
        >
          <div className="RecipeList-itemContent">
            <h2 className="RecipeList-title">{title}</h2>
            <p className="RecipeList-type">{type}</p>
          </div>
        </Link>
      </li>
    ))}
  </ul>
);

export default RecipeList;
