import React from "react";
import "./Recipe.scss";
import Ingredients from "./ingredients/Ingredients";
import Steps from "./steps/Steps";
import MisoMeta from "../miso-meta/MisoMeta";
import StructuredRecipeData from "./structured-recipe-data/StructuredRecipeData";
import { recipeType } from "../../types";

const Recipe = ({ recipe, recipe: { title, serves, ingredients, steps } }) => (
  <div className="Recipe">
    <h1 className="Recipe-heading">{title}</h1>
    {serves && <h4 className="Recipe-serves">Serves {serves}</h4>}
    <Ingredients ingredients={ingredients} />
    <Steps steps={steps} />

    <MisoMeta
      title={title}
      description={`A simple, delicious, tried and tested, vegan recipe for ${title.toLowerCase()}.`}
    />
    <StructuredRecipeData recipe={recipe} />
  </div>
);

Recipe.propTypes = {
  recipe: recipeType.isRequired
};

export default Recipe;
