import React, { useState } from "react";
import "./MultiIngredient.scss";
import Ingredient from "../ingredient/Ingredient";
import { multiIngredientType } from "../../../../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

const MultiIngredient = ({ multiIngredient: { variants, optional } }) => {
  const [current, setCurrent] = useState(0);

  return (
    <Ingredient
      ingredient={{ ...variants[current], optional }}
      icon={
        <FontAwesomeIcon
          className="MultiIngredient-marker"
          onClick={() => setCurrent((current + 1) % variants.length)}
          icon={faSyncAlt}
        />
      }
    />
  );
};

MultiIngredient.propTypes = {
  multiIngredient: multiIngredientType.isRequired
};

export default MultiIngredient;
