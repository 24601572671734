import React, { useState } from "react";
import "./RecipeToggle.scss";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { func } from "prop-types";

const RecipeToggle = ({ onToggled }) => {
  const [toggled, setToggled] = useState(true);

  return (
    <React.Fragment>
      <div className="RecipeToggle-separator" />
      <FontAwesomeIcon
        className="RecipeToggle-toggle"
        icon={faCheckCircle}
        onClick={() => {
          setToggled(!toggled);
          onToggled(!toggled);
        }}
        style={{ color: toggled && "#23B0FF" }}
      />
    </React.Fragment>
  );
};

RecipeToggle.propTypes = {
  onToggled: func.isRequired
};

export default RecipeToggle;
