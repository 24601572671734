const ALL_RECIPES = [
  {
    title: "Banana Bread",
    slug: "banana-bread",
    type: "Snack",
    serves: 8,
    ingredients: [
      { amount: "2 cups", name: "flour" },
      {
        variants: [
          { amount: "1 tbsp", name: "baking powder" },
          { amount: "1/4 tbsp", name: "baking soda" }
        ]
      },
      { amount: "1/2 tsp", name: "salt" },
      {
        variants: [
          { amount: "1 cup", name: "date syrup", slug: "date-syrup" },
          { amount: "1 cup", name: "sugar" }
        ]
      },
      { amount: "1/4 cup", name: "oil" },
      { amount: "4", name: "ripe bananas" },
      { amount: "1 tsp", name: "vanilla extract" }
    ],
    steps: [
      "Mix all of the dry ingredients together in one bowl.",
      "Mash the banana and mix the remaining ingredients together in another bowl.",
      "Add the dry mixture to the wet mixture and combine.",
      "Bake at 180 for 45 minutes."
    ]
  },
  {
    title: "Black Bean Stew",
    slug: "black-bean-stew",
    type: "Dinner",
    serves: 2,
    ingredients: [
      { amount: "2 tins", name: "black beans" },
      { amount: "2", name: "onions" },
      { amount: "5 cloves", name: "garlic" },
      { amount: "1 tin", name: "chopped tomatoes" },
      { amount: "1", name: "stock cube" },
      { amount: "1 tsp", name: "cumin" },
      { amount: "1/2 tsp", name: "ground ginger" },
      { amount: "2-4 tsp", name: "sugar" },
      { amount: "2 tsp", name: "oregano" }
    ],
    steps: [
      "Sweat the onions and then add the garlic.",
      "Add the chopped tomatoes.",
      "Add the beans and stock cube.",
      "Add more water if needed.",
      "Add the remaining ingredients and leave to simmer."
    ]
  },
  {
    title: "Chocolate Bread",
    slug: "chocolate-bread",
    type: "Snack",
    serves: 4,
    ingredients: [
      { amount: "1", name: "ripe banana" },
      { amount: "2", name: "flax eggs", slug: "flax-egg" },
      { amount: "1/4 cup", name: "cacao powder" },
      { amount: "2 cups", name: "flour" },
      { amount: "1/2 cup", name: "milk" },
      {
        variants: [
          { amount: "2 tbsp", name: "baking powder" },
          { amount: "1/2 tbsp", name: "baking soda" }
        ]
      },
      { amount: "2 tsp", name: "vanilla extract" },
      {
        variants: [
          { amount: "1/2 cup", name: "date syrup", slug: "date-syrup" },
          { amount: "1/2 cup", name: "sugar" }
        ]
      }
    ],
    steps: [
      "Mash the banana and mix in the remaining ingredients, including the flax egg mixture.",
      "Cook at 180 for 40-45 minutes."
    ]
  },
  {
    title: "Plain Pancakes",
    slug: "plain-pancakes",
    type: "Breakfast",
    serves: 2,
    ingredients: [
      { amount: "1.5 cups", name: "flour" },
      {
        variants: [
          { amount: "3 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "3 tbsp", name: "sugar" }
        ]
      },
      {
        variants: [
          { amount: "1 tsp", name: "baking powder" },
          { amount: "1/4 tsp", name: "baking soda" }
        ]
      },
      { amount: "1.5 cups", name: "milk" },
      { amount: "1 tbsp", name: "oil" },
      { amount: "1 tsp", name: "vanilla extract" }
    ],
    steps: [
      "Mix all of the ingredients together.",
      "Add more milk if needed, to get desired consistency.",
      {
        instruction: "Cook in a pan.",
        note:
          "Smaller pancakes are easier to turn. You may need to cook them in oil if they are sticking."
      }
    ]
  },
  {
    title: "Banana Pancakes",
    slug: "banana-pancakes",
    type: "Breakfast",
    serves: 2,
    ingredients: [
      { amount: "1", name: "ripe banana" },
      { amount: "1.5 tbsp", name: "oil" },
      {
        variants: [
          { amount: "1.5 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "1.5 tbsp", name: "sugar" }
        ]
      },
      {
        variants: [
          { amount: "2 tsp", name: "baking powder" },
          { amount: "1/2 tsp", name: "baking soda" }
        ]
      },
      { amount: "1 cup", name: "milk" },
      { amount: "2 cups", name: "flour" }
    ],
    steps: [
      "Mash the banana in a mixing bowl.",
      "Add the remaining ingredients except for the flour and mix well.",
      "Add the flour and stir to combine.",
      "Add more milk if needed, to get desired consistency.",
      {
        instruction: "Cook in a pan.",
        note:
          "Smaller pancakes are easier to turn. You may need to cook them in oil if they are sticking."
      }
    ]
  },
  {
    title: "Lentil Dahl",
    slug: "lentil-dahl",
    type: "Dinner",
    serves: 2,
    ingredients: [
      { amount: "2", name: "onions" },
      { amount: "3 cloves", name: "garlic" },
      { amount: "1 tin", name: "chopped tomatoes" },
      { amount: "1 tbsp", name: "ground ginger" },
      { amount: "1/2 tsp", name: "chilli powder" },
      { amount: "1/2 tsp", name: "cumin" },
      { amount: "1/2 tsp", name: "coriander powder" },
      { amount: "1/2 tsp", name: "mustard seeds" },
      { amount: "2 tsp", name: "turmeric" },
      { amount: "1 tsp", name: "garam masala" },
      { amount: "1", name: "stock cube" },
      { amount: "1 cup", name: "uncooked lentils" },
      { amount: "1 tin", name: "coconut milk" }
    ],
    steps: [
      "Sweat the onions, and then add the garlic.",
      "Add the chopped tomatoes and all of the spices.",
      "Add the lentils and coconut milk.",
      {
        instruction: "Cook until lentils are done.",
        note:
          "You will need to add more water as the lentils cook to reach desired consistency."
      }
    ]
  },
  {
    title: "Chocolate Quinoa",
    slug: "chocolate-quinoa",
    type: "Breakfast",
    serves: 2,
    ingredients: [
      { amount: "1 cup", name: "quinoa" },
      { amount: "1.5 cups", name: "water" },
      { amount: "1 cup", name: "milk" },
      { amount: "2 tbsp", name: "cacao powder" },
      { amount: "2 tbsp", name: "peanut butter" },
      {
        variants: [
          { amount: "2 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "2 tbsp", name: "sugar" }
        ]
      },
      { amount: "2 tbsp", name: "ground linseed", optional: true },
      { amount: "2", name: "bananas" }
    ],
    steps: [
      "Cook the quinoa in the water and milk on a low heat, stirring continuously.",
      "Once quinoa is mostly cooked, add the remaining ingredients (except the bananas) and stir.",
      "Put the cooked quinoa in bowls and add chopped bananas."
    ]
  },
  {
    title: "Marmite Almonds",
    slug: "marmite-almonds",
    type: "Snack",
    serves: 10,
    ingredients: [
      { amount: "1 tbsp", name: "marmite" },
      { amount: "1 tbsp", name: "water" },
      { amount: "250g", name: "almonds" }
    ],
    steps: [
      "In a pan, melt the marmite with the water.",
      "Once melted, remove from heat, add the almonds, and stir until evenly coated.",
      {
        instruction: "Roast the almonds at 180 for 15 minutes.",
        note:
          "Depending on the size of your dish, you may need to give the almonds a good stir halfway through so that they cook evenly."
      }
    ]
  },
  {
    title: "Oat Cookies",
    slug: "oat-cookies",
    type: "Snack",
    serves: 12,
    ingredients: [
      { amount: "1 cup", name: "flour" },
      { amount: "1.5 cups", name: "oats" },
      {
        variants: [
          { amount: "1/2 cup", name: "date syrup", slug: "date-syrup" },
          { amount: "1/2 cup", name: "sugar" }
        ]
      },
      { amount: "1 cup", name: "butter" },
      { amount: "3/4 cup", name: "raisins" },
      { amount: "1 tsp", name: "vanilla extract" },
      { amount: "1/2 tsp", name: "cinnamon powder" },
      {
        variants: [
          { amount: "1 tsp", name: "baking powder" },
          { amount: "1/4 tsp", name: "baking soda" }
        ]
      }
    ],
    steps: [
      "Mix all the ingredients.",
      "Divide into 12 even amounts and flatten into cookies.",
      "Bake the cookies at 180 for 20 minutes."
    ]
  },
  {
    title: "Houmous",
    slug: "houmous",
    type: "Snack",
    serves: 8,
    ingredients: [
      { amount: "2 tins", name: "chickpeas" },
      { amount: "8 tbsp", name: "oil" },
      { amount: "2 tbsp", name: "tahini" },
      { amount: "1/2 tbsp", name: "salt" },
      { amount: "1/2 tbsp", name: "pepper" },
      { amount: "2 cloves", name: "garlic", optional: true },
      { amount: "1 tbsp", name: "lemon juice", optional: true }
    ],
    steps: [
      "Drain the tins of chickpeas, keeping the water in the tins (aquafaba) for later.",
      {
        instruction: "Blend all of the ingredients in a food processor.",
        note:
          "A blender works too, but you'll need to add more aquafaba in the next step."
      },
      "Slowly add the aquafaba and blend until desired consistency.",
      "Add more salt or pepper to taste."
    ]
  },
  {
    title: "Date Syrup",
    slug: "date-syrup",
    type: "Ingredient",
    ingredients: [{ amount: "1 bag", name: "pitted dates" }, { name: "water" }],
    steps: [
      {
        instruction: "Put the dates in a food processor.",
        note:
          "A blender works too, but you'll need more water in the next step."
      },
      "Add water so that the dates are almost covered and leave to soak.",
      {
        instruction:
          "Once soaked, blend the dates until you get a thick syrup.",
        note:
          "I find that the dates soak quicker if I give them an inital blend in the water to break them up a bit."
      },
      "You can now use this as a sweetener in other recipes."
    ]
  },
  {
    title: "Shredded Sprouts",
    slug: "shredded-sprouts",
    type: "Side",
    serves: 1,
    ingredients: [
      { amount: "1 tbsp", name: "oil" },
      { amount: "5", name: "brussel sprouts" },
      { amount: "2 cloves", name: "garlic", optional: true },
      { amount: "1/2 tbsp", name: "sesame seeds", optional: true },
      { amount: "1/2 tbsp", name: "sunflower seeds", optional: true },
      { amount: "1/2 tsp", name: "salt" },
      { amount: "1/2 tsp", name: "pepper" }
    ],
    steps: [
      "Chop up the brussel sprouts until shredded.",
      "Heat the oil in a frying pan, and fry the brussel sprouts until they begin to lose their moisture (produce less steam) and start to go brown. Optionally add the garlic, sesame seeds, and sunflower seeds at this point too.",
      "Season to taste with salt and pepper."
    ]
  },
  {
    title: "Salt & Pepper Seeds",
    slug: "salt-and-pepper-seeds",
    type: "Snack",
    serves: 4,
    ingredients: [
      { amount: "1 tbsp", name: "oil" },
      { amount: "1 cup", name: "sunflower seeds" },
      { amount: "1/2 tbsp", name: "salt" },
      { amount: "1/2 tbsp", name: "pepper" }
    ],
    steps: [
      "Mix all of the ingredients in a baking dish.",
      "Bake at 180 for 30 minutes, or until seeds look dark brown."
    ]
  },
  {
    title: "Tomato Sauce",
    slug: "tomato-sauce",
    type: "Ingredient",
    serves: 2,
    ingredients: [
      { amount: "1", name: "onion" },
      { amount: "2 cloves", name: "garlic" },
      { amount: "2 tins", name: "chopped tomatoes" },
      { amount: "1 tbsp", name: "mixed herbs" }
    ],
    steps: ["Sweat the onion and garlic.", "Blend all the ingredients."]
  },
  {
    title: "Chia Pudding",
    slug: "chia-pudding",
    type: "Dessert",
    serves: 4,
    ingredients: [
      { amount: "5 tbsp", name: "chia seeds" },
      { amount: "1.5 cups", name: "milk" }
    ],
    steps: [
      {
        instruction: "Mix the seeds and milk together thoroughly.",
        note:
          "It's easiest to do this in the container that you intend to store the pudding in."
      },
      {
        instruction:
          "Continue mixing every minute or so for 15 minutes until the mixture has a nice consistency.",
        note:
          "WARNING: This step is important. If you leave the mix for too long initially then the seeds will clump together and the consistency of the pudding will suck."
      },
      {
        instruction: "Leave to thicken in the fridge overnight.",
        note:
          "It will be fine after only a few hours, but it will set more the longer you leave it."
      },
      "Can be eaten on its own or with anything else you fancy."
    ]
  },
  {
    title: "Easy Pasta",
    slug: "easy-pasta",
    type: "Dinner",
    serves: 2,
    ingredients: [
      { amount: "2 servings", name: "pasta" },
      { amount: "2 servings", name: "tomato sauce", slug: "tomato-sauce" }
    ],
    steps: [
      "Cook the pasta.",
      "Make the sauce.",
      {
        instruction:
          "Mix together on the hob until the sauce reduces to desired consistency.",
        note:
          "You'll probably need to keep an eye on it to make sure that the pasta doesn't stick to the pan."
      }
    ]
  },
  {
    title: "Pizza Base",
    slug: "pizza-base",
    type: "Ingredient",
    ingredients: [
      {
        variants: [
          { amount: "1 tsp", name: "date syrup", slug: "date-syrup" },
          { amount: "1 tsp", name: "sugar" }
        ]
      },
      { amount: "1.5 cups", name: "warm water (43°C)" },
      { amount: "1 tbsp", name: "active dry yeast" },
      { amount: "1 tbsp", name: "oil" },
      { amount: "1 tsp", name: "salt" },
      { amount: "2 cups", name: "wholewheat flour" },
      { amount: "1.5 cups", name: "all-purpose flour" }
    ],
    steps: [
      {
        instruction:
          "Add the yeast and date syrup to the water and leave for approx. 10 minutes to become foamy.",
        note:
          "Active dry yeast needs water at 43°C to dissolve and become active. If you don't have a thermometer - or you can't be bothered to measure it - just use the hottest water from your tap. I've found this works fine."
      },
      "Mix in the oil and salt, and then add the flour.",
      "Knead until the dough ball is smooth.",
      "Put the ball in an oiled bowl and turn to coat the surface.",
      "Leave in a warm place to double in size.",
      "The dough can now be used to make two thin crust pizzas or one thick crust."
    ]
  },
  {
    title: "Pie Crust",
    slug: "pie-crust",
    type: "Ingredient",
    ingredients: [
      { amount: "2/3 cup", name: "butter" },
      { amount: "2 cups", name: "flour" },
      { amount: "1 tsp", name: "salt" },
      { amount: "5 tbsp", name: "water" }
    ],
    steps: [
      "Cut the butter into the flour.",
      "Mix in the salt and water and knead into a ball.",
      {
        instruction: "Roll out the crust and place it into the dish.",
        note:
          "Rolling the dough between two sheets of baking paper makes it easier."
      },
      "Pre-bake at 180 until brown before adding filling."
    ]
  },
  {
    title: "Masala Chai",
    slug: "masala-chai",
    type: "Drink",
    serves: 2,
    ingredients: [
      { amount: "1.5 cups", name: "water" },
      { amount: "8", name: "cloves" },
      { amount: "8", name: "cardamom pods" },
      { amount: "1 tsp", name: "cinnamon powder" },
      { amount: "2 tsp", name: "ground ginger" },
      { amount: "2", name: "black tea bags" },
      {
        variants: [
          { amount: "3 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "3 tbsp", name: "sugar" }
        ]
      },
      { amount: "1/4 tsp", name: "pepper" },
      { amount: "2 cups", name: "milk" }
    ],
    steps: [
      "Simmer all of the ingredients, except for the milk, in a pan for 10 minutes. Crack open the cardamom pods first.",
      "Add the milk, and bring to a boil before removing from the heat.",
      "Squeeze out the tea bags, and serve the tea by pouring it through a strainer."
    ]
  },
  {
    title: "Smoky Tempeh",
    slug: "smoky-tempeh",
    type: "Side",
    serves: 2,
    ingredients: [
      { amount: "1 pack", name: "tempeh" },
      { amount: "1 tbsp", name: "soy sauce" },
      {
        variants: [
          { amount: "1 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "1 tbsp", name: "sugar" }
        ]
      },
      { amount: "1 cup", name: "water" },
      { amount: "1 tsp", name: "paprika" },
      { amount: "1 tsp", name: "pepper" }
    ],
    steps: [
      "Cut the tempeh into thin strips.",
      "Mix the remaining ingredients together to make the marinade.",
      "Lay the tempeh strips in a frying pan, and pour in the marinade.",
      "Fry the tempeh strips, flipping them every now and then, until the marinade has fully evaporated and the tempeh is cooked and sticky."
    ]
  },
  {
    title: "Dumpling Wrappers",
    slug: "dumpling-wrappers",
    type: "Ingredient",
    serves: 48,
    ingredients: [
      { amount: "2.5 cups", name: "flour" },
      { amount: "1 cup", name: "warm water" },
      { amount: "1/4 tsp", name: "salt" }
    ],
    steps: [
      "Mix all of the ingredients together.",
      "Knead the dough until smooth. Let the dough rest for at least 15 minutes.",
      {
        instruction: "Cut the dough into 48 small dough balls.",
        note:
          "An easy way to do this is to cut it into quarters, then cut each piece into thirds, and then cut each piece into quarters."
      },
      {
        instruction:
          "Roll out the dough balls into circles approximately 10cm in diameter.",
        note: "Cover the dough you're not working with or it will dry out."
      },
      {
        instruction:
          "You can now store these wrappers or use them to make dumplings!",
        note:
          "You'll need to generously dust the wrappers with flour if you intend to stack them for storage."
      }
    ]
  },
  {
    title: "Dumplings",
    slug: "dumplings",
    type: "Lunch",
    serves: 2,
    ingredients: [
      { amount: "12", name: "dumpling wrappers", slug: "dumpling-wrappers" },
      { name: "dumpling filling of your choice" },
      { amount: "1 tbsp", name: "oil" },
      { amount: "1 cup", name: "water" }
    ],
    steps: [
      "To make each dumpling, add a small amount of filling to the middle of each wrapper—you'll need less than you think!",
      "Wet the rim of the wrapper by wetting your finger and running it around the edge.",
      "Fold the wrapper in half and squeeze the edges together before crimping it to make a seal.",
      "Gently fry the dumplings (with the crimped edge facing up) in the oil in a frying pan that has a lid, until the bottoms are crispy.",
      "Put the lid on the pan and add small amounts of water over time to steam the dumplings.",
      "Steam the dumplings for about 5 minutes or until cooked.",
      "Serve with soy sauce and enjoy!"
    ]
  },
  {
    title: "Katsu Curry",
    slug: "katsu-curry",
    type: "Dinner",
    serves: 2,
    ingredients: [
      { amount: "4 tbsp", name: "oil" },
      { amount: "1", name: "large onion" },
      { amount: "4 cloves", name: "garlic" },
      { amount: "2 tbsp", name: "curry powder" },
      { amount: "1 tbsp", name: "cumin" },
      { amount: "1 tbsp", name: "coriander" },
      { amount: "1 tsp", name: "ginger powder" },
      { amount: "1 tsp", name: "cinnamon" },
      { amount: "1 tsp", name: "tumeric" },
      { amount: "1/2 tsp", name: "salt" },
      { amount: "1/2 tsp", name: "pepper" },
      { amount: "1/4 cup", name: "water" },
      { amount: "1 tbsp", name: "soy sauce" },
      {
        variants: [
          { amount: "1 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "1 tbsp", name: "sugar" }
        ]
      },
      {
        variants: [
          { amount: "1 tin", name: "coconut milk" },
          { amount: "1 cup", name: "milk" }
        ]
      },
      { name: "Epic breadcrumbs", slug: "epic-breadcrumbs" },
      { amount: "2", name: "sweet potatoes" }
    ],
    steps: [
      "Using 2 tbsps of the oil, fry the onions and garlic until translucent.",
      "Add all of the dry seasonings, and continue to fry for a few minutes before adding the water, soy sauce, date syrup, and coconut milk.",
      "Leave to reduce to desired consistency.",
      "Meanwhile, peel the sweet potatoes, and cut into slices about 1cm thick.",
      "Add the remaining oil and the sliced potatoes to a mixing bowl, and shake it around to coat the potato.",
      "Slowly add the breadcrumbs to the bowl whilst still shaking it to evenly coat the potato slices.",
      "Lay the potato slices out on a baking tray and spread any remaining breadcrumbs over the top.",
      "Bake for 30 minutes at 180."
    ]
  },
  {
    title: "Epic Breadcrumbs",
    slug: "epic-breadcrumbs",
    type: "Ingredient",
    ingredients: [
      { amount: "3 slices", name: "bread" },
      { amount: "2 tbsp", name: "ground linseed" },
      { amount: "2 tbsp", name: "sesame seeds" },
      { amount: "1/4 tsp", name: "salt" },
      { amount: "1/2 tsp", name: "pepper" }
    ],
    steps: [
      "Toast the bread, and then break it into small chunks.",
      {
        instruction:
          "Add the chunks of toast and the remaining ingredients to a food processor and blitz until breadcrumbs reach desired size.",
        note:
          "A blender will work as well, but you'll probably need to give it a shake while it is on to force the breadcrumbs to be fully blended."
      }
    ]
  },
  {
    title: "Roasted Asparagus",
    slug: "roasted-asparagus",
    type: "Side",
    serves: 2,
    ingredients: [
      { amount: "12", name: "asparagus spears" },
      { amount: "1 tbsp", name: "oil" },
      { amount: "1/4 tsp", name: "salt" },
      { amount: "1/2 tsp", name: "pepper" }
    ],
    steps: [
      {
        instruction: "Add all ingredients to a mixing bowl.",
        note: "It's easier if you cut the asparagus spears at least in half."
      },
      "Shake the bowl until the spears are evenly coated in oil, salt, and pepper.",
      {
        instruction:
          "Lay the spears on a baking tray and bake for 10 minutes at 180.",
        note:
          "Spears of different thicknesses will need different cooking times. 10 minutes works well for thinner spears."
      }
    ]
  },
  {
    title: "Flax Egg",
    slug: "flax-egg",
    type: "Ingredient",
    serves: 1,
    ingredients: [
      { amount: "1 tbsp", name: "ground linseed" },
      { amount: "2.5 tbsp", name: "water" }
    ],
    steps: ["Mix the linseed and the water, and leave for 5 minutes."]
  },
  {
    title: "Gingerbread",
    slug: "gingerbread",
    type: "Snack",
    serves: 6,
    ingredients: [
      { amount: "1", name: "flax egg", slug: "flax-egg" },
      {
        variants: [
          { amount: "1/2 cup", name: "brown sugar" },
          { amount: "1/2 cup", name: "raw sugar" }
        ]
      },
      { amount: "1/4 cup", name: "peanut butter" },
      { amount: "3 tbsp", name: "molasses" },
      { amount: "1/4 cup", name: "butter" },
      { amount: "1 tsp", name: "ground ginger" },
      { amount: "1/2 tsp", name: "ground cinnamon" },
      { amount: "1/4 tsp", name: "ground nutmeg", optional: true },
      { amount: "1/4 tsp", name: "salt" },
      {
        variants: [
          { amount: "1/4 tsp", name: "baking soda" },
          { amount: "1 tsp", name: "baking powder" }
        ]
      },
      { amount: "1.5 cups", name: "flour" }
    ],
    steps: [
      "Mix all ingredients except the flour.",
      "Mix flour with mixture until well combined.",
      "Roll out dough between 2 sheets of greaseproof paper, and then cut out your shapes.",
      "Keep re-rolling and cutting excess dough.",
      "Bake for 10-15 minutes at 180."
    ]
  },
  {
    title: "Chocolate Mug Cake",
    slug: "chocolate-mug-cake",
    type: "Dessert",
    serves: 1,
    ingredients: [
      { amount: "2 tbsp", name: "cacao powder" },
      { amount: "6 tbsp", name: "flour" },
      {
        variants: [
          { amount: "1/2 tsp", name: "baking powder" },
          { amount: "1/8 tsp", name: "baking soda" }
        ]
      },
      {
        variants: [
          { amount: "2.5 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "2.5 tbsp", name: "sugar" }
        ]
      },
      { amount: "4 tsp", name: "oil" },
      { amount: "6 tbsp", name: "milk" },
      { amount: "1/2 tsp", name: "vanilla extract" }
    ],
    steps: [
      "Mix all of the dry ingredients in a mug.",
      "Add the remaining ingredients and mix well.",
      "Bake in a microwave for anywhere between 1-3 minutes."
    ]
  },
  {
    title: "Flatbread",
    slug: "flatbread",
    type: "Side",
    serves: 5,
    ingredients: [
      { amount: "1 tsp", name: "active dry yeast" },
      {
        variants: [
          { amount: "1 tsp", name: "date syrup", slug: "date-syrup" },
          { amount: "1 tsp", name: "sugar" }
        ]
      },
      { amount: "3/4 cup", name: "warm water (43°C)" },
      { amount: "2 cups", name: "flour" },
      { amount: "1 tsp", name: "salt" },
      {
        variants: [
          { amount: "3/4 tsp", name: "baking powder" },
          { amount: "1/4 tsp", name: "baking soda" }
        ]
      },
      { amount: "3 tbsp", name: "oil" }
    ],
    steps: [
      {
        instruction:
          "Add the yeast and date syrup to the water and leave for approx. 10 minutes to become foamy.",
        note:
          "Active dry yeast needs water at 43°C to dissolve and become active. If you don't have a thermometer - or you can't be bothered to measure it - just use the hottest water from your tap. I've found this works fine."
      },
      "Meanwhile, in a mixing bowl, combine the flour, salt, and baking powder.",
      "Once the yeast mix is ready, add the oil and mix well.",
      "Add the yeast mix to the dry ingredients, and combine until a sticky dough forms.",
      {
        instruction: "Knead it into a ball.",
        note: "You may need to use extra flour."
      },
      {
        instruction:
          "Cover the dough and put it in a warm place for a couple of hours.",
        note:
          "A couple of hours is ideal, but half an hour will do if time is short. Putting the bowl in an oven, that is on as low a temperature is it will go, for 20-30 minutes works well too."
      },
      "Once the dough has risen, cut it into 5 equal pieces.",
      {
        instruction:
          "Roll each piece into a circle and cook for a minute on each side in a hot frying pan.",
        note: "They will rise a little and turn brown on the bottom."
      }
    ]
  },
  {
    title: "Raisin Mug Cake",
    slug: "raisin-mug-cake",
    type: "Dessert",
    serves: 1,
    ingredients: [
      { amount: "4 tbsp", name: "flour" },
      {
        variants: [
          { amount: "3/4 tsp", name: "baking powder" },
          { amount: "1/4 tsp", name: "baking soda" }
        ]
      },
      {
        variants: [
          { amount: "2 tbsp", name: "date syrup", slug: "date-syrup" },
          { amount: "2 tbsp", name: "sugar" }
        ]
      },
      { amount: "1 tbsp", name: "oil" },
      { amount: "2 tbsp", name: "raisins" },
      { amount: "5 tbsp", name: "milk" },
      { amount: "1/2 tsp", name: "vanilla extract" }
    ],
    steps: [
      "Mix all of the dry ingredients in a mug.",
      "Add the remaining ingredients and mix well.",
      {
        instruction: "Bake in a microwave for anywhere between 2-4 minutes.",
        note:
          "The timing depends on the microwave. Keep it going until the cake has risen and is fluffy."
      }
    ]
  },
  {
    title: "Quiche",
    slug: "quiche",
    type: "Dinner",
    serves: 4,
    ingredients: [
      { amount: "1", name: "pie crust", slug: "pie-crust" },
      { amount: "1", name: "onion" },
      { amount: "2 cups", name: "broccoli" },
      { amount: "3", name: "courgettes" },
      { amount: "3 cups", name: "silken tofu" },
      { amount: "2 tbsp", name: "nutritional yeast" },
      { amount: "1/2 tsp", name: "salt" },
      { amount: "1/2 tsp", name: "pepper" }
    ],
    steps: [
      {
        instruction:
          "Chop the onion, broccoli, and courgette, and bake at 180 for 20 minutes, or until they start to brown.",
        note:
          "Any vegetables will do, so don't feel like you need to stick to the recipe exactly."
      },
      "Meanwhile, blend the tofu, yeast, salt, and pepper in a food processor.",
      "Once the vegetables are ready, mix them thoroughly with the tofu mixture, and add it all to the dish with the pie crust.",
      "Bake at 180 for 50 minutes, or until the top of the quiche has hardened."
    ]
  }
].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

const findRecipe = title => ALL_RECIPES.find(recipe => recipe.slug === title);

export { ALL_RECIPES, findRecipe };
